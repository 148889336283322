<template>
  <base-section
    id="key-personnel"
  >
    <base-section-heading
      title="HOWELLCARE INDUSTRIES: KEY PERSONNEL"
    />
    <v-row
      class="justify-center mx-0"
    >
      <v-col
        v-for="(info, n) in information"
        :key="n"
        cols="1"
        class="px-0"
      >
        <v-img
          :src="info.src"
        />
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionKeyPersonnel',
    data () {
      return {
        information: [
          {
            src: require('@/assets/EZUDDIN.jpeg'),
          },
          {
            src: require('@/assets/NITHIYA-SAFETY.jpg'),
          },
          {
            src: require('@/assets/ESTHER-MARKETING.jpg'),
          },
          {
            src: require('@/assets/CHONG WEN BIN-PROCUREMENT.jpg'),
          },
          {
            src: require('@/assets/CHRISTOPHER- ENGINEERING.jpg'),
          },
          {
            src: require('@/assets/FRANCIS HO 1.jpg'),
          },
          {
            src: require('@/assets/JAMIL-HR ADMIN.jpg'),
          },
          {
            src: require('@/assets/Leow Siao Wei.jpg'),
          },
          {
            src: require('@/assets/ERIC KOO- BUSINESS DEVELOPMENT.jpg'),
          },
          {
            src: require('@/assets/RAHMAN-QA.jpg'),
          },
          {
            src: require('@/assets/SUFHAIRI- LOGISTICS.jpg'),
          },
        ],
      }
    },
  }
</script>
